<template>
  <base-section id="polistakaful">
    <v-container class="mt-n15" v-if="title === 'vehicle'">
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">Kendaraan</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">Kalkukasi</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">Polis</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              class="mb-12"
              height="100%"
              flat
            >
              <template>
                <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <v-row>
                  <v-col
                    cols="12">
                    <v-alert
                      border="bottom"
                      colored-border
                      type="info"
                      elevation="2"
                    >
                     <span v-if="form.coverage === 0">Menjamin segala jenis kerusakan pada mobil Anda termasuk kerusakan ringan, berat mau pun kehilangan total akibat pencurian.</span>
                     <span v-else>Menjamin kerugian/kerusakan di mana biaya perbaikan ≥ 75% dari harga mobil termasuk kehilangan total akibat pencurian.</span>
                    </v-alert>
                  </v-col>
                  <v-col
                    md = "4"
                    sm = "12"
                    cols="12"
                  >
                  <v-btn-toggle
                    v-model="form.type"
                    mandatory
                  >
                    <v-btn @click  = "GetType(0)">
                      <v-icon>mdi-car</v-icon>
                      Mobil
                    </v-btn>
                    <v-btn @click  = "GetType(1)">
                      <v-icon>mdi-motorbike</v-icon>
                      Motor
                    </v-btn>
                  </v-btn-toggle>
                  </v-col>
                  <v-col
                    md = "4"
                    sm = "12"
                    cols="12"
                  >
                  <v-btn-toggle
                    v-model="form.coverage"
                    mandatory
                  >
                    <v-btn @click = "GetCoverage(0)">
                      Comprehensive
                    </v-btn>
                    <v-btn @click = "GetCoverage(1)">
                      Total Loss Only
                    </v-btn>
                  </v-btn-toggle>
                  </v-col>
                  <v-col
                    md = "4"
                    sm = "12"
                    cols="12"
                  >
                  <v-text-field
                      label="Plat Nomor"
                      required="required"
                      placeholder="Plat Nomor"
                      outlined
                      v-model = "form.licenseno"
                      :rules  = "form.licensenoRules"
                      :readonly= "true"
                      :prefix = "form.licensenoprefix"
                    />
                  </v-col>
                  <v-col
                    md = "4"
                    sm = "12"
                    cols="12"
                  >
                  <v-text-field
                      label="Merk"
                      required="required"
                      placeholder="Merk Kendaraan"
                      outlined
                      v-model = "form.brand_desc"
                      :rules  = "form.brandRules"
                      :readonly = "true"
                      @click  = "OpenDialog('brand')"
                    />
                  </v-col>
                  <v-col
                    md = "4"
                    sm = "12"
                    cols="12"
                  >
                  <v-text-field
                      label="Model"
                      required="required"
                      placeholder="Model Kendaraan"
                      outlined
                      v-model = "form.model_desc"
                      :rules  = "form.modelRules"
                      :readonly = "true"
                      @click  = "OpenDialog('model')"
                    />
                  </v-col>
                  <v-col
                    md = "4"
                    sm = "12"
                    cols="12"
                  >
                  <v-text-field
                      label="Sub Model"
                      required="required"
                      placeholder="Sub Model Kendaraan"
                      outlined
                      v-model = "form.submodel_desc"
                      :rules  = "form.submodelRules"
                      :readonly = "true"
                      @click  = "OpenDialog('submodel')"
                    />
                  </v-col>
                  <v-col
                    md = "4"
                    sm = "12"
                    cols="12"
                  >
                  <v-select
                    :items="years"
                    label="Tahun Kendaraan"
                    outlined
                    prepend-inner-icon="mdi-format-list-bulleted-square"
                  ></v-select>
                  </v-col>
                  <v-col
                    md = "4"
                    sm = "12"
                    cols="12"
                  >
                  <v-select
                    :items="form.used"
                    item-text="gendtab_desc"
                    item-value="gendtab_id"
                    label="Penggunaan"
                    outlined
                    prepend-inner-icon="mdi-format-list-bulleted-square"
                  ></v-select>
                  </v-col>
                  <v-col
                    md = "4"
                    sm = "12"
                    cols="12"
                  >
                  <v-btn-toggle
                    v-model="form.condition"
                    mandatory
                  >
                    <v-btn>
                      Baru
                    </v-btn>
                    <v-btn>
                      Bekas
                    </v-btn>
                  </v-btn-toggle>
                  </v-col>
                  <v-col
                    md = "6"
                    sm = "12"
                    cols="12"
                  >
                  <v-text-field
                      label="Harga Kendaraan"
                      required="required"
                      placeholder="Harga Kendaraan"
                      outlined
                      v-model = "form.tsi"
                      :rules  = "form.tsiRules"
                      prefix  = "IDR"
                      v-money="format_money"
                    />
                  </v-col>
                  <v-col
                    md = "6"
                    sm = "12"
                    cols="12"
                  >
                  <v-text-field
                      label="Aksesoris(Jika Ada)"
                      required="required"
                      placeholder="Aksesoris"
                      outlined
                      v-model = "form.accessories"
                    />
                  </v-col>
                </v-row>
                </v-form>
                </v-container>
              </template>
            </v-card>
            <v-btn
              color="primary"
              @click="Process"
            >
              Lanjut
            </v-btn>

            <!-- <v-btn text>Cancel</v-btn> -->
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card
              class="mb-12"
              color="grey lighten-1"
              height="200px"
            ></v-card>

            <v-btn
              color="primary"
              @click="e1 = 3"
            >
              Continue
            </v-btn>

            <v-btn text>Cancel</v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card
              class="mb-12"
              color="grey lighten-1"
              height="200px"
            ></v-card>

            <v-btn
              color="primary"
              @click="e1 = 1"
            >
              Continue
            </v-btn>

            <v-btn text>Cancel</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
       <v-dialog
          v-model="dialog"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="body-1 grey lighten-2">
              Submit?
            </v-card-title>

            <v-card-text class="body-2">
              
            </v-card-text>


            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="dialog = false"
              >
                Batal
              </v-btn>
              
              <v-btn
                color="primary"
                text
                :disabled="dialog_ok === 'Ok' ? false:true"
              >
                {{dialog_ok}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
            :timeout="snackbar_timeout"
            v-model="snackbar"
          >
            {{ snackbar_text }}
          <v-btn
              color="pink"
              text
              @click="snackbar = false"
            >
              Close
         </v-btn>
        </v-snackbar>
        <v-dialog
          v-model="dialog_general"
          persistent
          fullscreen
          scrollable
        >
          <v-card>
            <v-card-title class="body-1 grey lighten-2">
              {{dialog_general_title}}
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="dialog_general = false"
              >
                Batal
              </v-btn>
            </v-card-title>
            <v-card-text class="body-2">
              <v-text-field
                label="Cari Data"
                id="finddata"
                prepend-inner-icon="mdi-magnify"
                v-model ="dialog_general_search"
                autocomplete="off"
              ></v-text-field>
              <v-list flat>
                  <v-list-item
                    v-for="item in resultQuery"
                    :key="item.gendtab_id"
                    @click="GetData(item.gendtab_id,item.gendtab_desc)"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="item.gendtab_desc"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutUs',

    data: () => ({
      e1: 1,
      format_money: {
          decimal: ',',
          thousands: '.',
          precision: 0
      },
      title: '',
      years: [],
      brand: [],
      show: {
        years: true,
        brand: false,
      },
      dialog_general: false,
      dialog_general_flag: 'brand',
      dialog_general_title: 'Merk Kendaraan',
      dialog_general_search: null,
      form: {
        years: '',
        general: [],
        licensenoprefix: '',
        licenseno: '',
        licensenoRules: [
           v => !!v || 'Mohon masukkan plat nomor kendaraan'
        ],
        brand_id : '',
        brand_desc : '',
        brandRules: [
           v => !!v || 'Mohon masukkan merk kendaraan'
        ],
        model_id : '',
        model_desc : '',
        modelRules: [
           v => !!v || 'Mohon masukkan model kendaraan'
        ],
        submodel_id : '',
        submodel_desc : '',
        submodelRules: [
           v => !!v || 'Mohon masukkan sub model kendaraan'
        ],
        condition: 0,
        tsi: 0,
        tsiRules: [
          v => !!v || 'Mohon masukkan harga Kendaraan'
        ],
        accessories: '',
        type: 0,
        coverage: 0,
        used: [],
      },
      valid: true,
      dialog: false,
      dialog_ok: 'Ok',
      snackbar: false,
      snackbar_text: '',
      snackbar_timeout: 5000,
      nopol: '',
      nopolRules: [
         v => !!v || 'Mohon masukkan nomor polis anda',
         v => (v && v.length > 3) || 'Nomor polis tidak boleh kurang dari 3 huruf',
      ],
      name: '',
      nameRules: [
         v => !!v || 'Mohon masukkan nama anda',
         v => (v && v.length > 1) || 'Nama tidak boleh 1 huruf',
      ],
      phone: '',
      phoneRules: [
         v => !!v || 'Mohon masukkan nomor handphone anda',
         v => Number.isInteger(Number(v)) || "Mohon isi dengan angka",
      ],
      email: '',
      emailRules: [
         v => !!v || 'Mohon masukkan email anda',
         v => /.+@.+\..+/.test(v) || 'Email tidak valid',
      ],
      notes: '',
      notesRules: [
         v => !!v || 'Mohon masukkan catatan/pengaduan anda',
         v => (v && v.length > 10) || 'Catatan tidak boleh kurang dari 10 huruf',
      ],
      flagvehicle: 0
    }),
    created () {
      this.Load()
    },
    computed: {
      resultQuery(){
        if(this.dialog_general_search){
        return this.form.general.filter((item)=>{
          return this.dialog_general_search.toLowerCase().split(' ').every(v => item.gendtab_desc.toLowerCase().includes(v))
        })
        }else{
          return this.form.general
        }
      }
    },
    methods: {
      Load () {
        this.Years()
        this.Use()
        let data = this.$route.params.type
        this.form.licensenoprefix = this.$route.params.id
        this.form.licenseno       = this.$route.params.data
        this.title = data
      },
      Years () {
        let i
        let today    = new Date()
        let yyyy     = today.getFullYear()
        let prevy    = today.getFullYear() - 5
        for (i = yyyy; i >= prevy; i --) {
          this.years.push(i)
        }
      },
      Use () {
        let formData = new FormData()
        formData.append('gendtab_actived', 'Y')
        formData.append('gendtab_refid', 'V09')
        formData.append('order_by', 'gendtab_desc')
        formData.append('order_type', 'ASC')
        this.$axios.post(this.$functions.SafeURL('apiListGenTableSimple'),formData,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.form.used = response.data
        })
        .catch(e => {
            this.snackbar = true
            this.snackbar_text = e
            this.snackbar_timeout = 0
        })
      },
      Brand () {
        this.snackbar = true
        this.snackbar_text = 'Loading Merk....'
        let formData = new FormData()
        formData.append('gendtab_actived', 'Y')
        formData.append('gendtab_refid', 'V01')
        formData.append('order_by', 'gendtab_desc')
        formData.append('order_type', 'ASC')
        this.$axios.post(this.$functions.SafeURL('apiListGenTableSimple'),formData,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.form.general = response.data
            this.snackbar     = false
        })
        .catch(e => {
            this.snackbar = true
            this.snackbar_text = e
            this.snackbar_timeout = 0
        })
      },
      Model () {
        this.snackbar = true
        this.snackbar_text = 'Loading Model....'
        let flagmotor = ''
        if (this.flagvehicle === 1) {
          flagmotor = 'MOTOR'
        }
        let formData = new FormData()
        formData.append('gendtab_actived', 'Y')
        formData.append('gendtab_refid', 'V02')
        formData.append('gendtab_fieldid_1', 'V01')
        formData.append('gendtab_flagmotor', flagmotor)
        formData.append('gendtab_fieldvalueid_1', this.form.brand_id)
        formData.append('order_by', 'gendtab_desc')
        formData.append('order_type', 'ASC')
        this.$axios.post(this.$functions.SafeURL('apiListGenTableSimple'),formData,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.form.general = response.data
            this.snackbar     = false
        })
        .catch(e => {
            this.snackbar = true
            this.snackbar_text = e
            this.snackbar_timeout = 0
        })
      },
      SubModel () {
        this.snackbar = true
        this.snackbar_text = 'Loading Sub Model....'
        let formData = new FormData()
        formData.append('gendtab_actived', 'Y')
        formData.append('gendtab_refid', 'V03')
        formData.append('gendtab_fieldid_1', 'V02')
        formData.append('gendtab_fieldvalueid_1', this.form.model_id)
        formData.append('order_by', 'gendtab_desc')
        formData.append('order_type', 'ASC')
        this.$axios.post(this.$functions.SafeURL('apiListGenTableSimple'),formData,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.form.general = response.data
            this.snackbar     = false
        })
        .catch(e => {
            this.snackbar = true
            this.snackbar_text = e
            this.snackbar_timeout = 0
        })
      },
      GetData (id, desc) {
        if (this.dialog_general_flag === 'brand') {
          this.form.brand_id    = id
          this.form.brand_desc  = desc
          this.form.model_id    = ''
          this.form.model_desc  = ''
        } else if (this.dialog_general_flag === 'model') {
          this.form.model_id    = id
          this.form.model_desc  = desc
          this.form.submodel_id = ''
          this.form.submodel_desc = ''
        } else if (this.dialog_general_flag === 'submodel') {
          this.form.submodel_id   = id
          this.form.submodel_desc = desc
        }
        this.dialog_general = false
      },
      Close (flag) {
        switch (flag) {
          case 'years':
            this.form.years = ''
            this.show.years = true
            this.HideAll()
          break
          case 'brand':
            this.form.brand       = ''
            this.form.brand_desc = ''
            this.show.brand = true
          break
        }
      },
      HideAll () {
        this.show.brand     = false
      },
      OpenDialog (flag) {
        switch (flag) {
          case "brand":
            this.dialog_general_search = ''
            this.dialog_general_flag = flag
            this.dialog_general      = true
            this.dialog_general_title = 'Merk Kendaraan'
            this.Brand()
            setTimeout(function(){
            document.getElementById('finddata').focus()
            }, 500)
          break
          case "model":
          if (this.form.brand_id !== '') {
            this.form.general = []
            this.dialog_general_search = ''
            this.dialog_general_flag = flag
            this.dialog_general      = true
            this.dialog_general_title = 'Model Kendaraan'
            this.Model()
            setTimeout(function(){
            document.getElementById('finddata').focus()
            }, 500)
          } else {
            this.snackbar = true
            this.snackbar_text = 'Mohon pilih merk terlebih dahulu'
          }
          break
          case "submodel":
          if (this.form.brand_id !== '' && this.form.model_id !== '') {
            this.form.general = []
            this.dialog_general_search = ''
            this.dialog_general_flag = flag
            this.dialog_general      = true
            this.dialog_general_title = 'Sub Model Kendaraan'
            this.SubModel()
            setTimeout(function(){
            document.getElementById('finddata').focus()
            }, 500)
          } else {
            this.snackbar = true
            this.snackbar_text = 'Mohon pilih merk/model terlebih dahulu'
          }
          break
        }
      },
      GetType (id) {
        this.form.coverage = id
        this.flagvehicle = id
      },
      GetCoverage (id) {
        if (id === 0){
          if (this.form.type === 1) {
            this.snackbar = true
            this.snackbar_text = 'Motor hanya bisa Total Loss Only'
            this.form.coverage = 1
            let data = this
            setTimeout(function(){
            data.form.coverage = 1
            }, 500)
          }
        }
      },
      Process () {
        let formData = new FormData()
        formData.append('fastflowrate_category', 'VEHICLE')
        formData.append('fastflowrate_id', 'CAR')
        formData.append('fastflowrate_type', 'MAIN')
        formData.append('fastflowrate_tsi', this.quotation_mobil_tsi)
        formData.append('fastflowrate_add_tsi', this.quotation_mobil_tjh)
        formData.append('fastflowrate_add_tsi_2', this.quotation_mobil_padriver)
        formData.append('fastflowrate_add_tsi_3', this.quotation_mobil_papassenger)
        formData.append('fastflowrate_region', this.quotation_mobil_platnomorid)
        formData.append('fastflowrate_period', this.quotation_mobil_period)
        formData.append('fastflowrate_cover', this.quotation_mobil_main)
        formData.append('fastflowrate_passenger', this.quotation_mobil_passenger)
        // formData.append('fastflowrate_add1', earthquake)
        // formData.append('fastflowrate_add2', flood)
        // formData.append('fastflowrate_add3', rscc)
        // formData.append('fastflowrate_add4', terrorist)
        // formData.append('fastflowrate_add5', tpl)
        // formData.append('fastflowrate_add6', driver)
        // formData.append('fastflowrate_add7', passenger)
        formData.append('fastflowrate_discount', this.quotation_mobil_discount)
        this.$axios.post(this.$functions.SafeURL('apiListFastFlowRate', ``), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        })
        .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
              if (feedback[0].feedback === 'Y') {
                this.e1 = 2
              }
            }
        })
        .catch(e => {
            this.snackbar = true
            this.snackbar_text = e
            this.snackbar_timeout = 0
        })
      }
    }
  }
</script>
